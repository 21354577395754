import { Link } from 'gatsby';
import { defineMessages, FormattedMessage } from 'react-intl';
import Layout from '../../components/layout';
import Seo from '../../components/Seo/Seo';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import successImg from './img/success.svg';
import * as styles from './OrderSuccess.module.scss';

const messages = defineMessages({
    metTitle: {
        id: 'orderSuccess.metTitle',
        defaultMessage: 'Order success - Cheap social media services | Cheap-Social.com',
    },
    metaDescription: {
        id: 'orderSuccess.metaDescription',
        defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. itur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
});

const OrderSuccess = ({ serverData }) => (
    <Layout>
        <Seo
            title={messages.metTitle}
            description={messages.metaDescription}
        />
        <div className={styles.container}>
            <div className={styles.text}>
                <h1 className={styles.title}>
                    <img src={successImg} alt="Success" />
                    <FormattedMessage id="orderSuccess.title" defaultMessage="Success!" />
                </h1>
                <p className={styles.post}>
                    <FormattedMessage id="orderSuccess.post" defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. itur tincidunt ante odio, eget sollicitudin mi sollicitudin non." />
                </p>
                <Link to="/" className={styles.btn}>
                    <FormattedMessage id="orderSuccess.goBack" defaultMessage="Go back to main page" />
                </Link>
            </div>
            <div>
                <OrderSummary
                    name={serverData.category}
                    serviceName={serverData?.serviceName}
                    amount={serverData.quantity}
                    price={parseFloat(serverData.price)}
                    align="right"
                />
            </div>
        </div>
    </Layout>
);

export default OrderSuccess;

export async function getServerData({ params }) {
    const { id } = params;
    try {
        const res = await fetch(`${process.env.GATSBY_API_URL}/api/order/${id}`);
        if (!res.ok) {
            throw new Error('Response failed');
        }
        return {
            props: await res.json(),
        };
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {},
        };
    }
}
